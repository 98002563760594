//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SanghaAvatar from '@/components/SanghaAvatar.vue';
import { Users } from '@/services/api.service';
import { NUMBER_OF_RESULTS_PER_PAGE } from '@/services/config';

export default {
  name: 'UserPickerModalComponent',
  data() {
    return {
      showModal: true,

      currentPage: 0,
      hasNextPage: false,

      modalUsersList: [],
      userSearch: {
        searchString: null,
      },
      inProgress: null,
      currentOptions: {
        limit: NUMBER_OF_RESULTS_PER_PAGE,
        offset: 0,
        sortBy: 'FAVORITES',
      },

      buttonIntersectionOptions: {
        handler: this.onLoadMoreButtonIntersection,
      },
      intersectionStarted: false,

      selectedUsers: [],
    };
  },
  props: [
    'show',
    'color',
    'title',
    'buttonTitle',
    'multiple',
    'excludeSelf',
    'excludeChannelId',
  ],
  components: {
    SanghaAvatar,
  },
  methods: {
    searchUser(options) {
      if (options) {
        this.currentOptions = { ...this.currentOptions, ...options };
      }

      if (this.excludeChannelId) {
        this.currentOptions.excludeChannelId = this.excludeChannelId;
      }

      this.inProgress = true;
      Users.explore(this.currentOptions).then(
        (res) => {
          if (options && options.offset > 0) {
            this.modalUsersList = [
              ...this.modalUsersList,
              ...(res.data.data.usersSearch || []),
            ];
          } else {
            this.modalUsersList = res.data.data.usersSearch;
          }
          this.inProgress = false;
          this.hasNextPage = res.hasNextPage;
          // this.currentPage = 0;
          setTimeout(() => {
            this.intersectionStarted = true;
          }, 500);
        },
        () => {
          this.inProgress = false;
        }
      );
    },
    loadNextPage() {
      this.currentPage = this.currentPage + 1;
      const options = {
        offset: NUMBER_OF_RESULTS_PER_PAGE * this.currentPage,
      };
      this.searchUser(options);
    },
    loadNextPageIntersection(obj) {
      if (!this.inProgress && obj.isIntersecting && obj.intersectionRatio > 0)
        this.loadNextPage();
    },
    searchUserByName() {
      const options = {
        limit: NUMBER_OF_RESULTS_PER_PAGE,
        offset: 0,
        searchString: this.userSearch.searchString,
      };
      this.searchUser(options);
    },
    onLoadMoreButtonIntersection(obj) {
      if (!this.intersectionStarted) {
        return;
      }
      if (!this.inProgress && obj.isIntersecting && obj.intersectionRatio > 0) {
        this.intersectionStarted = false;
        this.loadNextPage();
      }
    },

    handleClick(user) {
      if (this.multiple) {
        if (this.selectedUsers.indexOf(user.id) > -1) {
          this.selectedUsers.splice(this.selectedUsers.indexOf(user.id), 1);
        } else {
          this.selectedUsers.push(user.id);
        }
      } else {
        this.$emit('closeUserPicker', user);
      }
    },
  },
  watch: {
    show(val) {
      if (val) this.selectedUsers = [];
    },
    showModal() {
      this.$emit('closeUserPicker', null);
    },
  },
  computed: {},
  mounted() {
    this.selectedUsers = [];
    this.searchUser();
  },
};
